<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'bts'"
          :AllResponse="'allbts'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'BTs'"
          :sheetname="'BTs'"
          @open="OpenBTForm"
          @hrf="OpenBTForm"
          @rowselect="BTChange"
          :showedit="false"
          :key="klist"
          :Add="true"
          :del="true"
          :del_disable="bt.nbfiche > 0"
          :search_elm="search"
          @delete="deleteItem"
          :list_options="true"
          :isExpire="false"
          :groupby="[1, 2, 3, 4, 6, 7, 8, 12]"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <btform
      :item="bt"
      :key="kbt"
      :showForm="!isBTClosed"
      :readonly="bt.statut_id > 7"
      :editer="editer"
      :valider="valider"
      :solder="solder"
      :open_orders="open_orders"
      :cmds_details="cmds_details"
      :machines="machine_list"
      @close="closeBTForm"
      @refresh="refresh"
    >
    </btform>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import BTS from "../graphql/Production/BTS.gql";
import ALLBTS from "../graphql/Production/ALLBTS.gql";
//import ACTUAL_ORDERS from "../graphql/Mouvment/ACTUAL_ORDERS.gql";
//import DELETE_ORDER_BT from "../graphql/Production/DELETE_ORDER_BT.gql";
import DELETE_BT from "../graphql/Production/DELETE_BT.gql";
import ALLDETAILSCMDS_LIGHT from "../graphql/Mouvment/ALLDETAILSCMDS_LIGHT.gql";
import MACHINES from "../graphql/Production/MACHINES.gql";
export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    btform: () => import("../components/BTForm.vue"),
  },
  props: {},
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editer: false,
    solder: false,
    valider: false,
    klist: 100,
    kbt: 300,
    search: "",
    drawer: null,
    selitem: {},
    cmds_details: [],
    machine_list: [],
    headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
        index: 0,
      },
      {
        text: "N° OF",
        value: "no_of",
        enum: "NO_OF",
        selected: true,
        index: 1,
      },
      {
        text: "N° BT",
        value: "no_bt",
        enum: "NO_BT",
        slot: "href",
        selected: true,
        index: 2,
      },
      {
        text: "Client",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        index: 3,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        enum: "NOCMD",
        selected: true,
        index: 4,
      },

      {
        text: "Produit",
        value: "product_label",
        enum: "PRODUCT_LABEL",
        selected: true,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbprod",
        valeur: "1",
        width: "400",
        hoover_list: "details",
        hoover_name: "label",
        sortable: false,
        index: 5,
      },
      {
        text: "Ordre",
        value: "job_order",
        selected: true,
        index: 6,
      },
      {
        text: "Date",
        value: "bt_date",
        slot: "date",
        enum: "BT_DATE",
        selected: true,
        index: 7,
      },

      {
        text: "Machine",
        value: "machine_name",
        enum: "MACHINE_NAME",
        selected: true,
        index: 8,
      },
      {
        text: "Qte Lancée",
        value: "qte",
        enum: "QTE",
        align: "end",
        selected: false,
        index: 9,
      },
      {
        text: "Qte Fabriqué",
        value: "qte_fab",
        enum: "QTE_FAB",
        align: "end",
        selected: false,
        index: 10,
      },
      {
        text: "Reste",
        value: "qte_rest",
        enum: "QTE_REST",
        align: "end",
        selected: false,
        index: 11,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: true,
        index: 12,
      },
    ],
    open_orders: [],
    isBTClosed: true,
    bt: {},
    changed: false,
    loading: false,
  }),

  computed: {
    Qselect() {
      return BTS;
    },
    Qselectall() {
      return ALLBTS;
    },
  },
  watch: {},
  created() {},

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02082") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03082") || this.$store.state.isadmin;
    this.solder =
      this.$store.state.auth.includes("04082") || this.$store.state.isadmin;

    this.get_data();
  },

  methods: {
    async refresh() {
      let w = {
        AND: [
          {
            column: "CMD_TYPE",
            operator: "IN",
            value: [1, 3],
          },
          { column: "STATUT_ID", value: 7 },
          { column: "REST_FAB", operator: "GT", value: 0 },
          { column: "REST_RCPT", operator: "GT", value: 0 },
          { column: "CANCELED", value: 0 },
        ],
      };
      let v = {
        where: w,
        Kind:
          this.$store.state.auth.includes("03077") || this.$store.state.isadmin
            ? 1
            : 0,
        expire: 1,
      };
      this.isloading = true;
      let r = await this.requette(ALLDETAILSCMDS_LIGHT, v);
      this.isloading = false;
      if (r) {
        this.cmds_details = r.alldetailscmds_light.filter(
          (elm) => elm.product_statut_id == 2
        );
      }
      // let v = {
      //   where: {
      //     AND: [
      //       { column: "QTE_REST", operator: "GT", value: 0 },
      //       { column: "STATUT_ID", value: 7 },
      //     ],
      //   },
      // };
      // let r = await this.requette(ACTUAL_ORDERS, v);
      // if (r) this.open_orders = r.allorders;
    },

    async get_data() {
      let r = await this.requette(MACHINES);
      if (r) {
        this.machine_list = r.machines;
      }
      this.refresh();
    },
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async maj(query, v, r) {
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          if (r) this.klist++;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    async deleteItem(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer ce job?"
        )
      ) {
        let v = {
          id: item.id,
        };
        await this.maj(DELETE_BT, v);
        await this.refresh();
        this.klist++;
      }
    },
    BTChange(item) {
      this.bt = item;
      this.selitem = item;
    },
    OpenBTForm(item) {
      this.bt = item;
      this.isBTClosed = false;
      this.kbt++;
    },
    closeBTForm(item) {
      this.selitem = item;
      this.bt = item;
      this.isBTClosed = true;
    },
  },
};
</script>
